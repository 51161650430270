import Vue from 'vue'
import App from './App.vue'
import axios from './router/axios';
import VueAxios from 'vue-axios';
import router from './router/router';
import ElementUI from 'element-ui';
import './assets/js/rem' // 缩放自适应
import 'element-ui/lib/theme-chalk/index.css';
import './assets/js/vants'
import wxjssdk from 'jweixin-module'
function px2rem(px){
  if(/%/ig.test(px)){ // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  }else{
    return (parseFloat(px) / 16) + 'rem'
  }
}
Vue.prototype.$wx = wxjssdk
Vue.prototype.$px2rem = px2rem // 放到全局
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
