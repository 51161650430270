<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import DevicePixelRatio from './assets/js/devicePixelRatio'
export default {
  name: "app",
  data() {
    return {};
  },
  watch: {
    '$route': {
      handler: function () {
        var infoPc = navigator.userAgent;
        //通过正则表达式的test方法判断是否包含“Mobile”字符串
        var isPhone = /mobile/i.test(infoPc);
        let pageWidth = document.documentElement.clientWidth
        if (!isPhone && pageWidth >= 1200) {
          new DevicePixelRatio().init()
        }
      },
      immediate: true
    }
  },
  created() {
    document.title = '报告传媒'
    var infoPc = navigator.userAgent;
    //通过正则表达式的test方法判断是否包含“Mobile”字符串
    var isPhone = /mobile/i.test(infoPc);
    let pageWidth = document.documentElement.clientWidth
    if (!isPhone && pageWidth >= 1200) {
      new DevicePixelRatio().init()
    }

  },
  methods: {},
  computed: {}
};
</script>
<style lang="scss">
@import "./assets/font/font.css";

* {
  font-family: "Alibaba-PuHuiTi-Regular";
}

@media screen and (max-width: 1200px) {
  body {
    height: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  body {
    height: 100vh;
    margin: 0;
  }
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.avue--detail .el-col {
  margin-bottom: 0;
}
</style>
